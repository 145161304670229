export const isVideo = (videoUri) => {
    const type = videoUri.split('.').pop().toLowerCase()
    switch (type) {
      case 'webm':
        return true
      case 'mpg':
        return true
      case 'mp2':
        return true
      case 'mpeg':
        return true
      case 'mpe':
        return true
      case 'mpv':
        return true
      case 'ogg':
        return true
      case 'mp4':
        return true
      case 'm4p':
        return true
      case 'm4v':
        return true
      case 'avi':
        return true
      case 'wmv':
        return true
      case 'mov':
        return true
      case 'qt':
        return true
      case 'flv':
        return true
      case 'swf':
        return true
      case 'avchd':
        return true
      default:
        return false
    }
  }
  
  export const isPhoto = (videoUri) => {
    if (!videoUri) return false
    const type = videoUri.split('.').pop().toLowerCase()
    switch (type) {
      case 'jpg':
        return true
      case 'jpeg':
        return true
      case 'png':
        return true
      case 'gif':
        return true
      case 'webp':
        return true
      case 'tiff':
        return true
      case 'bmp':
        return true
      case 'heif':
        return true
      case 'svg':
        return true
      case 'eps':
        return true
      case 'pdf':
        return true
      case 'psd':
        return true
      case 'ai':
        return true
      case 'xcf':
        return true
      case 'indd':
        return true
      case 'raw':
        return true
      default:
        return false
    }
  }
  

// export const regexInput = (input) => {
//   return input.replace(
//     /(<([^>]+)>)|[\t\r\n\.#$?'<>"/\[\]]|(?:https:\/\/)|(?:http:\/\/)/gi,
//     ''
//   )
// }

export const removeApos = (input) => {
  if (!input) return ''
  return input.replace(/'/g, '"')
}

export const addApos = (input) => {
  if (!input) return ''
  return input.replace(/"/g, "'")
}

export const metaDescription = (input) => {
  if (!input) return ''

  return input.replace(/"/g, "'")
    .replace(/<p>/g, '')
    .replace(/<\/p>/g, '')
    .replace(/<h3>/g, '')
    .replace(/<\/h3>/g, '')
    .replace(/<b>/g, '')
    .replace(/<\/b>/g, '')
    .replace(/<i>/g, '')
    .replace(/<\/i>/g, '')
    .replace(/<ol>/g, '')
    .replace(/<\/ol>/g, '')
    .replace(/<ul>/g, '')
    .replace(/<\/ul>/g, '')
    .replace(/<lii>/g, '')
    .replace(/<\/li>/g, '')
    .replace(/<br\/> /g, '')
}